.alert-message {max-width: 83.3333%; margin-left: auto; padding-left:15px; margin-bottom: 1rem; margin-top: -8px; color: #181818; font-size:12px; line-height: 14px;}

.back-arrow{display: none;}
.new-aler-outer h2 i svg{color: #667085;  width: 20px;} 
label.custom-checkbox.invalid {
    border-color: var(--bs-form-invalid-color);
}
/* .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: var(--bs-form-invalid-color);
} */


/* .check-inner{position: relative;}
.check-inner:after{position:absolute; left:0; right:0; top:0; bottom:0; background:#f00; content:''; border-radius:50%; z-index:0; display:none;}
.check-inner input{-webkit-user-select: none;   -moz-user-select: none;    -ms-user-select: none;    user-select: none;}

.check-inner .form-check-input:checked[type="checkbox"]{background-image: none !important; background: transparent;} 

.check-inner .form-check-input:checked{background: #00f;} */


@media only screen and (max-width:767px){
    .alert-message{max-width: 100%; padding:0;}
}