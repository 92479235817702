/* ForgotPassword.css */

.display-screen{
    height: 100vh;
}

.forgot-password-container {
    box-shadow: 6px;
    margin-top: 50px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-title {
    margin-bottom: 30px;
    color: #040F0F !important;
    font-weight: 600;
    text-align: center;
  }

  .custom-alert {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
  .forgot-password-btn{width: 100%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
.forgot-password-btn:hover{background:#3D95A8; border: #fff;}

.email-fld{width: 100%;}
.email-fld input{border: 1px solid #D9D9D9; height: 48px;  background: #fff; font-size: 16px; color: #181818; border-radius: 4px; width: 100%;}

.back-to-home, .back-to-home:hover{
  color: #2E3394;
  text-decoration: underline;
}
.back-to-home:hover {
  color: #3D95A8;
  /* border: #fff; */
}



  @media (max-width: 1024px) {
    .custom-alert {
      margin-top: 40px !important;
    }
  }

