.organization-overview-container {
  height: calc(100vh - 160px);
  overflow: hidden;
  overflow-y:auto;
  padding: 20px 0;
  scrollbar-width: none;
}

.welcome-text h2{color: #667085; font-weight: 400;}
.welcome-text h2 span{color: #040F0F; font-weight: 600;}
.org-tabs{margin-top: 35px;}
.org-tabs ul{gap:4px; display: flex; border: none; }
.org-tabs li{max-width: calc(100% / 4 - 4px); width: 100%;}
.org-tabs li button{width: 100%; border: 1px solid #E0E0E0 !important; background: #FAFAFA; color: #667085; font-size: 16px; line-height: 24px; border-radius: 8px !important; box-shadow:none; font-weight: 500;}
.org-tabs li button:hover,  .org-tabs li button.active{background: #2E3394 !important; color: #fff !important; font-weight: 500;}

.alt-mgt-outer {border:1px solid #E0E0E0; width: 100%; padding: 20px; border-radius: 16px;}
.list-group{display: flex; width: 100%; flex-wrap: wrap; gap:16px; flex-direction: inherit;}
.alert-item{max-width: calc(100% / 2 - 8px); width: 100%; padding: 12px 16px;  background: #FAFAFA; border: 1px solid #E0E0E0 !important;  font-size: 16px; color: #040F0F; border-radius: 6px;}

.client-admin li{max-width: calc(100% / 3 - 4px);}

.back-alt-inner{padding:16px; border-radius:16px; border:1px solid #E0E0E0;}
.back-alt-inner button{margin-bottom: 0 !important; color: #667085; font-weight: 600; font-size: 24px; display: flex; align-items: center; padding: 0;}
.back-alt-inner button svg{margin-right: 5px;}

.new-aler-outer{padding: 16px; border-radius:16px; border:1px solid #E0E0E0;  margin-top: 16px;}

.new-aler-outer h2{margin-bottom: 25px;}

.assigned-users-label {width: 100%;}
.assign-title{margin-bottom: 15px; color: hsl(0deg 0% 20% / 64%); margin-left: -6px;}
.assign-items-name button{padding: 0px; position: relative; top:-3px;}
.assign-items-name{display: flex; gap: 15px; flex-wrap: wrap; }
.assign-items-name .list-group-item{max-width: calc(100% / 3 - 15px);  width: 100%; border:1px solid #D9D9D9; border-radius: 50px;}
.assign-items-name .list-group-item {color:#040F0F !important;} 

.assign-items-name .btn-link svg{width: 18px; height: 18px; position: relative; top:2px;}

.notification-gtp{display: flex; width: 100%; justify-content: space-between; gap:10px;}
.notification-gtp .custom-checkbox { width: 100%; cursor: pointer; max-width: calc(100% / 2 - 10px); font-size: 16px; line-height: 24px;  border: 1px solid #D9D9D9;    padding: 11px 16px;    border-radius: 99px;   display: flex;    justify-content: space-between;    align-items: center;}
.notification-gtp .form-check-input{float: right !important; margin: 0px !important; border-radius: 50% !important; border:2px solid #040F0F; cursor: pointer;  position:relative;}
.notification-gtp .form-check-input:after{position:absolute; left:2px; right:2px; top:2px; bottom:2px; background:#000; content:''; border-radius:50%; z-index:0;  opacity:0; visibility: hidden;}
.notification-gtp .form-check-input:checked{background-color:transparent; background-image: none; width:a}

.notification-gtp .form-check-input:checked:after{opacity: 1; visibility: visible;}


.role-gtp {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.role-gtp .custom-radio { width: 100%; cursor: pointer; max-width: calc(100% / 2 - 10px); font-size: 16px; line-height: 24px;  border: 1px solid #D9D9D9;    padding: 11px 16px;    border-radius: 99px;   display: flex;    justify-content: space-between;    align-items: center;}


.role-gtp .form-check-input {
  float: right !important;
  margin: 0px !important;
  border-radius: 50% !important;
  border: 2px solid #000; /* Black border */
  cursor: pointer;
  position: relative;
}

.role-gtp .form-check-input:after {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  background: #000; /* Black circle */
  content: '';
  border-radius: 50%;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
}

.role-gtp .form-check-input:checked {
  background-color: transparent;
  background-image: none;
}

.role-gtp .form-check-input:checked:after {
  opacity: 1;
  visibility: visible;
}

.permissions-gtp{display: flex; width: 100%; justify-content: space-between; gap:10px;}
.permissions-gtp .custom-checkbox { width: 100%; cursor: pointer; max-width: calc(100% / 2 - 10px); font-size: 16px; line-height: 24px;  border: 1px solid #D9D9D9;    padding: 11px 16px;    border-radius: 99px;   display: flex;    justify-content: space-between;    align-items: center;}
.permissions-gtp .form-check-input{float: right !important; margin: 0px !important; border-radius: 50% !important; border:2px solid #040F0F; cursor: pointer;  position:relative;}
.permissions-gtp .form-check-input:after{position:absolute; left:2px; right:2px; top:2px; bottom:2px; background:#000; content:''; border-radius:50%; z-index:0;  opacity:0; visibility: hidden;}
.permissions-gtp .form-check-input:checked{background-color:transparent; background-image: none; width:a}

.permissions-gtp .form-check-input:checked:after{opacity: 1; visibility: visible;}

.selected-forms-box{width: 100%; }
.selected-forms-label{width: 100%;}
.form-title{margin-bottom: 15px; color: hsl(0deg 0% 20% / 64%); margin-left: -6px;}
.form-items-name button{padding: 0px; position: relative; top:-3px;}
.form-items-name{display: flex; gap: 15px; flex-wrap: wrap; }
.form-items-name .list-group-item{max-width: calc(100% / 3 - 15px);  width: 100%; border:1px solid #D9D9D9; border-radius: 50px;}
.form-items-name .list-group-item {color:#040F0F;} 

.location-parameter-box{width: 100%; }
.location-parameter-label{width: 100%;}
.location-parameter-title{margin-bottom: 15px; color: hsl(0deg 0% 20% / 64%); margin-left: -6px;}
.location-parameter button{padding: 0px; position: relative; top:-3px;}
.location-parameter{display: flex; gap: 15px; flex-wrap: wrap; }
.location-parameter .list-group-item{max-width: calc(100% / 2 - 8px);  width: 100%; border:1px solid #D9D9D9; border-radius: 50px;}
.location-parameter .list-group-item {color:#040F0F;} 

.assigned-teams-box{width: 100%; }
.assigned-teams-label{width: 100%;}
.team-title{margin-bottom: 15px; color: hsl(0deg 0% 20% / 64%); margin-left: -6px;}
.team-items-name button{padding: 0px; position: relative; top:-3px;}
.team-items-name{display: flex; gap: 15px; flex-wrap: wrap; }
.team-items-name .list-group-item{max-width: calc(100% / 2 - 8px);  width: 100%; border:1px solid #D9D9D9; border-radius: 50px;}
.team-items-name .list-group-item {color:#040F0F;} 
.list-group-item {color:#040F0F !important;} 

.alt-buttons{width: 100%; display: flex; justify-content: space-between;}
.alt-buttons button{max-width: calc(100% / 2 - 15px); width: 100%; padding: 15px 15px;}
.alt-buttons button.btn-primary{background:#2E3394; color: #fff;}
.alt-buttons button.btn-primary:hover{background: #3D95A8;  color: #fff;}
.alt-buttons button.btn-outline-secondary{background: #3D95A8; color: #fff; }
.alt-buttons button.btn-outline-secondary:hover{background: #2E3394; color: #fff;}
.mobile-menu {  display: none;  }
.mobile-tab-content{display: none;}
.new-aler-outer h2 i{display: none;}

.back-arrow i{display: none;}



.alt-mgt-edit .btn{background: #FAFAFA; border:1px solid #E0E0E0; border-radius: 6px; color: #667085; padding: 7px 40px;}
.alt-mgt-edit .btn:hover{background: #3D95A8; color: #fff;} 

.create-mgt-alt .btn{background: #2E3394; color: #fff; font-size: 16px; line-height: 26px; padding: 10px 60px; border-radius: 6px; }
.create-mgt-alt .btn:hover{background:#3D95A8;}


.new-aler-outer input::placeholder {
  color: hsl(0deg 0% 20% / 64%);
}


.new-aler-outer input::-moz-placeholder{
  color: hsl(0deg 0% 20% / 64%);
}

.new-aler-outer input:-ms-input-placeholder{
color: hsl(0deg 0% 20% / 64%);
}

.new-aler-outer input::-webkit-input-placeholder{
color: hsl(0deg 0% 20% / 64%);
}

.new-aler-outer textarea::placeholder {
  color: hsl(0deg 0% 20% / 64%);
}


.new-aler-outer textarea::-webkit-input-placeholder {
  color: hsl(0deg 0% 20% / 64%);
  }
  
  .new-aler-outer textarea:-moz-placeholder { /* Firefox 18- */
    color: hsl(0deg 0% 20% / 64%);
  }
  
  .new-aler-outer textarea::-moz-placeholder {  /* Firefox 19+ */
    color: hsl(0deg 0% 20% / 64%);
  }
  
  .new-aler-outer textarea:-ms-input-placeholder {
    color: hsl(0deg 0% 20% / 64%);
  }


  .back-button button{background: #FAFAFA; border-color: #E0E0E0; color: #667085; font-weight: 400; padding: 7px 30px;}

  .alt-mgt-title span{display: none;}
  .team-arrow img{display: none;}

@media only screen and (max-width:1650px){
  /* .organization-overview-container { min-height: calc(100vh - 180px);} */

  
}

@media only screen and (max-width:991px){
  .org-tabs ul{flex-wrap: wrap; gap:10px;}
  .org-tabs li{max-width: calc(100% / 2 - 10px);}

  .organization-overview-container{height: auto; margin-bottom: 50px; }
}

@media only screen and (max-width:767px){
  .notification-gtp{flex-wrap: wrap; }
  .notification-gtp .custom-checkbox{max-width: 100%;}
  .alt-buttons{flex-wrap: wrap;}
  .alt-buttons button{width: 100%; max-width: 100%; margin-right: 0px !important; margin-bottom: 10px;}
  .alt-buttons button:last-child{margin-bottom: 0px;}
  .back-alt-inner{padding: 0px;}
  .back-alt-inner button{font-size: 16px; line-height: 24px;}
  .new-aler-outer h2{margin-bottom: 10px; }
  .org-tabs ul{flex-wrap: wrap; gap: 10px;}
  .org-tabs li{max-width: 100%; }
  .back-button{width: 100%; margin-top: 10px;}
  .back-button button{width: 100%;}
  .org-tabs{margin-top: 15px;}
  .list-group{flex-wrap: wrap;}
  .alert-item{max-width: 100%;}
  .alt-mgt-outer .d-flex{flex-wrap: wrap;}
  .alt-mgt-outer  h2{margin-bottom: 10px;}

  .org-tabs {display: none; }
  .mobile-menu { display: block; width: 40px; margin-top:0px; height: 40px; display: flex; justify-content: flex-start; align-items: center;}    
  .mobile-menu button{border: none; background:transparent; padding: 0;}
  .mobile-menu button:hover{background: transparent; }
  .mobile-menu .dropdown-toggle::after{display: none;}
  .mobile-menu .dropdown-toggle{position: relative; z-index:9999; background: transparent; border:none; }
  .mobile-menu .dropdown-menu{position: fixed !important; top:0px !important; margin-top:0px !important; transform: none !important;  left: -230px !important; display: block !important; opacity: 0; visibility: hidden;  border-radius: 0px !important;  height: 100vh; padding-top: 110px; transition: all 0.5s ease-in-out; width:260px;}
  .mobile-menu .show.dropdown-menu {transform: none !important; left: 0px !important; transition: all 0.5s ease-in-out; display: block !important; opacity: 1; visibility: visible;}  
  .mobile-tab-content{display: block;}

  .dropdown-menu {gap:32px; display: flex; width: 100%; flex-wrap: wrap;}
  .dropdown-menu a{padding: 18px 16px; color: #040F0F; font-size: 16px; font-weight: 600; display: block; margin-bottom: 30px;}
  .dropdown-menu a:last-child{margin-bottom: 0px;}
  .dropdown-menu a.active-tab{color: #fff; background: #2E3394;}
  .welcome-text h2{text-align: left;}
  .account-management-container{margin-top: 30px; padding: 0px; border: none;}
  .alt-mgt-outer{margin-top: 30px; padding: 0px; border: none;}
  .alt-mg-inner {flex-wrap: wrap;}
  .alt-mgt-title{width: 100%; }
  .alt-mgt-edit{width: 100%;}
  .alt-mgt-edit button{width: 100%; border-color: #E0E0E0; background: #FAFAFA; color: #667085; border-radius: 6px; padding: 10px 15px;} 
  .alt-mgt-edit button:hover{ background: #2F2D91;}
  .create-mgt-alt {width: 100%;}
  .create-mgt-alt button{width: 100%; background: #2F2D91; color: #fff; border-radius: 8px; padding: 10px 15px;}
  .create-mgt-alt button:hover{background: #3D95A8;}
  /* .organization-overview-container{min-height: 81vh;} */

  .back-alt-inner{display: none;}
  .new-aler-outer{padding: 0px; border:none; margin-bottom: 20px;}
  .new-aler-outer h2 i{display: inline-block;}
  .new-aler-outer h2{font-size:20px; line-height: 28px;}
  .back-arrow i{display: inline-block;}

  .back-button{display: none;}

  .create-mgt-alt .btn, .alt-mgt-edit .btn{padding: 7px 20px; font-size: 16px; line-height: 26px;}

  .organization-overview-container{margin-bottom: 10px;}
  
  .col-form-label br{display: none;}
  .alt-mgt-outer{margin-bottom: 20px;}
  .alt-mgt-title{display: flex;}
  .alt-mgt-title span{margin-top: 3px; display: inline-block; margin-right: 7px;}
  .team-arrow img{margin-top: 3px; display: inline-block; margin-right: 7px;}

  .assign-items-name .list-group-item, .form-items-name .list-group-item, .location-parameter .list-group-item{max-width: 100%;}
  .notification-label{   margin-left: 5px;}

  .team-items-name .list-group-item{max-width: 100%;}

}

@media only screen and (max-width:767px){
  .organization-overview-container{margin-bottom: 57px;}
}

