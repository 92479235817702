.save-team-btn{width: 30%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
.save-team-btn:hover{background:#3D95A8; border: #fff;}

.team-fld{width: 100%;}
.team-fld input{border: 1px solid #D9D9D9; height: 48px;  background: #fff; font-size: 16px;  border-radius: 4px; width: 100%; color: #181818 !important;}

.timezone-select .react-select__control {
    line-height: 35px;
  }
  .timezone-select .react-select__input {
    min-width: 400px;
    color: #181818 !important;
  }

  .react-select__control--menu-is-open .react-select__indicator {
    transform: rotate(180deg) !important; /* Rotate arrow */
    }