.main-container {
    min-height: 100vh;
    display: block;
    position: relative;
    padding-bottom: 100px;
}

.tabs-container {
    position: absolute;
    top: 10%;
    width: 100%;
    height: 85%;
    padding: 0 5% 5% 5%;
    overflow: auto;
}

.tabs-container .nav-link {
    background-color: #fafafa !important;
    color: #0000004d !important;
}

.tabs-container .nav-link.active {
    background-color: #2f2d91 !important;
    color: #fff !important;
}

.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card-container .section {
    margin: 1%;
    width: 100%;
}

.org-h3 {
    width: 100%;
    color: #667085;
    font-size: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.card-title {
    color: #040f0f;
    font-weight: 600 !important;
    padding: 16px;
}

.ctl-btn {
    background: #fafafa !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

@media (max-width: 1000px) {
    .ctl-btn {
        width: 95%;
    }
}

.dashboard-btn {
    background: #2e3394 !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

@media (max-width: 1000px) {
    .dashboard-btn {
        width: 100%;
        margin: auto;
    }
}

.anomaly-btn {
    background: #3d95a8 !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

@media (max-width: 1000px) {
    .anomaly-btn {
        width: 100%;
    }
}

.team-sect {
    padding: 12px;
}

.team-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.team-sect-btn {
    color: #040f0f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

@media (max-width: 1000px) {
    .team-sect {
        padding: 12px 0;
    }
}

.orgname-text {
    color: #040f0f;
}

.list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.list-container .verification-status{
    position: absolute;
    top: -10px;
    right: -8px;
}

@media (max-width: 1000px) {
    .list-container {
        flex-direction: column;
    }
}

.list-item {
    /* width: 24%; */
    margin: 6px;
    border: 0.5px solid #fafafa;
    background: #fafafa !important;
    height: 40px;
    padding: 8px 16px;
    position: relative;
    background: red;
}

.account-sect {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.account-sect-child{
    display: flex;
    width: 100px !important;
    justify-content: space-between;
}

.account-ops {
    background: none;
    border: none;
    padding: 0;
    margin-left: 16px;
    text-decoration: none;
    width: fit-content;
}

.homepage {
    height: 100vh;
    overflow: hidden;
}

.team-add-btn {
    width: 100%;
}

.team-btn-ctn {
    right: 0%;
}

.org-btn {
    background: #fafafa !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

@media (max-width: 1000px) {
    .org-btn {
        width: 100%;
    }
}

.org-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.org-btn-container {
    display: flex;
    width: 100%;
    justify-content: right;
}

body,
html {
    overflow: hidden;
    height: 100%;
    margin: 0;
}

.text {
    font-family: Inter !important;
    color: #2f2d91 !important;
}


@media only screen and (max-width:991px){
    body, html{
        height: auto;
        overflow: initial;
    }
}


@media screen and (max-width: 500px) {
    .account-sect-username {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px; /* Adjust the width as needed */
    }
}
