.account-management-container{width: 100%; padding: 30px; border: 1px solid #E0E0E0; border-radius: 16px;}
.account-man-mn{display: flex; width: 100%; justify-content: space-between; margin-bottom: 16px;}
.account-edit-btn{display: inline-block;}
.account-edit-btn button{padding: 6px 60px; background: #FAFAFA; border:1px solid #E0E0E0; color: #667085; font-size: 16px; line-height: 28px; border-radius: 6px;}
.account-edit-btn button:hover{background: #2E3394;}
.account-user-btns{display: flex; gap:16px;  margin-bottom: 30px;}
.account-user-btns button{padding: 10px 80px; color: #fff; font-weight: 400; border-radius: 6px; border:1px solid transparent; font-size: 16px; line-height: 26px; }
.add-user{background: #2E3394;}
.acc-resend-verify{background: #3D95A8; padding: 10px 43px !important;}

.add-user:hover{background: #3D95A8;}
.acc-resend-verify:hover{background: #2E3394;}

.account-list-outers h4{margin-bottom: 16px;}
.account-list-grp{width: 100%; }
.account-list-outer{display: flex; width: 100%; flex-wrap: wrap; gap:16px;}
.account-list-outer .acc-box{max-width: calc(100% / 3 - 12px); padding: 12px 16px; width: 100%; border:1px solid #E0E0E0; background: #FAFAFA; border-radius: 6px;}

.acc-edit-buttons{display: flex; gap:16px; } 
.acc-edit-buttons button{padding: 0; color: #2F2D91 !important; text-decoration: none !important; line-height: 18px;}
.acc-edit-buttons button:hover{color: #040F0F !important;}

.account-name-sym{display: flex; flex: 0 0 auto; align-items: center;}
.account-name-sym span{display: inline-block; margin-left: 8px; position: relative; top:-2px; }
.account-name-sym span svg {fill: #3D95A8; font-size: 13px;}
.account-name-sym .pending-verification svg{fill: #E0E0E0;}

.account-name-sym em{font-style: normal;  overflow: hidden;  white-space: nowrap;  text-overflow: ellipsis;  max-width: 20ch;}



@media only screen and (max-width:1439px){
    .account-list-outer .acc-box{max-width: calc(100% / 3 - 12px);}
    .account-management-container{padding: 20px;}
}

@media only screen and (max-width:1199px){
    .account-list-outer .acc-box{max-width: calc(100% / 2 - 12px);}
}

@media only screen and (max-width:991px){

}

@media only screen and (max-width:767px){
    .account-list-outer .acc-box{max-width: 100%;   }
    .account-user-btns{flex-wrap: wrap;}
    .account-user-btns button{width: 100%;}
    .account-user-btns{margin-bottom: 20px;}
    .account-man-mn{width: 100%; flex-wrap: wrap; gap:16px;}
    .account-man-mn h2{width: 100%;}
    .account-edit-btn{width: 100%; }
    .account-edit-btn button{width: 100%;}

    .account-management-container{padding: 0px; border: none;}

}