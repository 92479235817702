/* ForgotPassword.css */

.display-screen{
    height: 100vh;
}

.forgot-password-container {
    box-shadow: 6px;
    margin-top: 50px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-title {
    margin-bottom: 30px;
    color: #343a40;
    font-weight: 600;
    text-align: center;
  }
  
  .forgot-password-button {
    width: 100%;
    background-color: #007bff;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .forgot-password-button:hover {
    background-color: #0056b3;
  }
  
  .custom-alert {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
  }
  
  .back-to-home-btn{
    width: 100%;
    text-align: center;
    margin-top: '10px';
  }
  