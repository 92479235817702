.pwd-not-match-msg {
    color: red;
}


.reset-password-btn{width: 30%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
.reset-password-btn:hover{background:#3D95A8; border: #fff;}

@media (max-width: 1024px){
    .reset-password-btn   {
        width: auto;
    }
}

