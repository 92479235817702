.footer {
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.footer .copyright {
    color: #667085;
    margin: 1vw;
    font-size: min(2vw, 16px); 
}

.footer .terms {
    margin: 1vw;
    font-size: min(2vw, 16px); 
    color: #667085;
    text-decoration: underline;
}

@media only screen and (max-width: 575px){
    .footer{z-index:8; }
    .footer{flex-wrap: wrap; flex-direction: column-reverse; background: #fff !important;}
    .footer .copyright{font-size: 13px !important; margin: 0; margin-top: 16px;}
    .footer .terms{font-size: 13px !important; margin: 0;}
    
}