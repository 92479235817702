  .alertList-container{height: calc(100vh - 156px); overflow:auto; padding-bottom: 15px; margin-top: 40px !important;}
  .alert-table-outer {max-width: 100%; padding:0 15px;}
  .alert-table-title{display: flex; justify-content: space-between; align-items: center;}
  .alert-table-title h4{font-size:24px; line-height: 24px; color: #040F0F; text-align: center; }
  .alert-table-title h4 span{color: #667085;}
  .alert-table-title h4 svg{
    margin-top: -4px !important;
  }

  .alert-table {text-align: center; margin-bottom: 0;}
  .alert-table td, .alert-table th{font-size: 14px; line-height: 16px; font-weight: 400; padding: 16px;}
  .alert-table td{color: #040F0F;}
  .alert-table tr:nth-child(2n+1) td{background: #fff;}
  .alert-table tr:nth-child(2n) td{background: #E0E0E0;}

  .pagination-outer{margin-top: 5px; display: flex; justify-content: space-between ; margin-bottom: 45px;}

  
  .paginations-inner{display: flex; align-items: center; }
  .paginations-inner button{width: 24px; height: 24px; background: #040F0F; border-radius: 4px; display: flex; justify-content: center; align-items: center; padding: 1px;}
  .paginations-inner input{width: 36px; height: 36px; border-radius: 8px; border:1px solid #34343480; display: flex; align-items: center; justify-content: center; margin: 0 10px; text-align: center;}
  .paginations-inner span{margin-left: 10px; color: #040F0F; font-size: 14px; }

  .pagesize-ctn input{width: 36px; height: 36px; border-radius: 8px; border:1px solid #34343480; display: flex; align-items: center; justify-content: center; margin: 0 10px; text-align: center;}
  .pagesize-ctn label{color: #040F0F; font-size: 14px; }


  .no-data {
    text-align: center !important;
    color: #667085 !important;
    font-size: 24px !important;
    line-height: 24px !important;
    height: 50vh !important; 
  display: table-cell !important;
  vertical-align: middle !important;
  }

  .alert-table-tools{
    margin-top: 20px;
    padding: 16px;

  }
  .alert-table-tools .react-select__indicator-separator{display: none;}
  .alert-table-tools .react-select__dropdown-indicator {
    color: #040F0F !important;
  }
  .alert-table-tools .react-select__control{border: 1px solid #D9D9D9; border-radius: 4px; min-width: 226px; height: 40px; font-size: 16px; color: #667085;}
  .alert-table-tools .react-select__control--is-focused{border: 1px solid #D9D9D9; box-shadow: none;}
  .alert-table-tools .react-select__value-container{padding: 0 16px; }
  .alert-table-tools .react-select__single-value {
    color: #667085 !important;
  }
  .alert-table-title-lgt{
    display: flex;
    max-width: calc(100% - 250px); 
    width: 100%; align-items: center;
  }

  .alert-table-title-lgt label{margin-right: 10px; }
  .download-btn-outer{display: inline-block;}
  .download-btn-outer .download-btn{height: 40px;}
  .alert-table-title-rgt {display: flex; justify-content: flex-end;}
  .alert-table-title-rgt .alter-rgt-select{max-width:226px; width: 100%;}



  @media only screen and (max-width:991px){
    .aalert-table-outer-inner {display: flex; width: 100%; margin-bottom: 20px; margin-top: 16px; justify-content: space-between;}
    .alert-left-mobile{max-width: calc(100% - 200px); width: 100%; display: flex; align-items: center;}
    .alert-rgt-mobile{max-width: 190px; width: 100%; display: flex; justify-content: flex-end;}
    .alert-left-mobile label{margin-right: 15px;}
    .alt-rgt-mob-select{width: 100%;}
  }

  @media only screen and (max-width:767px){
     .alert-table-title{
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 32px !important;
    }

    .alert-row  .field-names {font-size: 13px; line-height: 16px;}
    .alert-row  .text-center {font-size: 13px; line-height: 16px;}
    .alert-row{margin-bottom: 8px !important; padding: 8px 0;}

    .pagination-outer{margin-top: 20px; flex-wrap: wrap; justify-content: center;  margin-bottom: 45px;}
    .paginations-inner{width: 100%; justify-content: center; margin-bottom: 20px;}
    .pagesize-ctn{width: 100%; justify-content: center; margin: 0; padding: 0px;}
    .pagination-outer  div:first-child{display: none;}

    .pagesize-ctn{flex-direction: row;}

    .aalert-table-outer-inner{margin-top: 0; flex-wrap: wrap;}
    .alert-left-mobile {max-width: 100%; flex-wrap: wrap; flex-direction: column; margin-bottom: 0px !important;}
    .alert-rgt-mobile {max-width: 100%; margin-top: 16px;}
    .alert-left-mobile .download-btn-outer{margin-top: 16px; width: 100%; margin-left: -15px;}
    .alert-left-mobile .download-btn-outer .download-btn{width: 100%; margin-left: 0; height: 38px; text-align: center; justify-content: center;}
    .alert-left-mobile  label{margin-bottom: 16px; width: 100%; text-align: left;}
    .alert-left-mob-select{width: 100%; margin-left: -15px;}
    .alert-table-title h4 span{overflow: hidden;      white-space: nowrap;       text-overflow: ellipsis; max-width: 200px; display: block; margin-top: 10px; margin-bottom:10px} 
    .alert-left-mobile .react-select__dropdown-indicator {
      color: #040F0F !important;
    }
    .alert-rgt-mobile .react-select__dropdown-indicator {
      color: #040F0F !important;
    }
  }


@media only screen and (max-width:575px){
  .alert-table-title h4 span{
    max-width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    line-height: 30px;
  }
  .no-data {
    display: block !important;
    margin-top: 80px !important;
    height: auto !important;
  }
  .alertList-container{
    padding-bottom: 30px !important;
  }
}