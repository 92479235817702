.modal-dialog{max-width: 860px; }
.modal-header{border:none; padding:40px; padding-bottom: 0;}
.modal-body{padding:40px; padding-top: 24px; }
.modal-title{font-size: 24px; line-height: 30px; font-weight: 600; color: #040F0F;}
.modal-header .btn-close{background-size: 11px 11px;}

.modal-fld{display: flex;  align-items: center; margin-bottom: 16px; flex-wrap: wrap; }
.modal-fld Label{max-width: 162px; width: 100%; flex:  0 0 auto;  margin-bottom:0; font-size: 16px; color:#040F0F; font-weight:400;}
.txx-fld{max-width: calc(100% - 162px); width: 100%;}

.txx-fld input{border: 1px solid #D9D9D9; height: 48px;  background: #fff; font-size: 16px; color: #181818; border-radius: 4px; width: 100%;}

.input-with-icon{display: flex; border:1px solid #D9D9D9; padding: 0 10px;  width: 100%; border-radius: 4px;}
.input-with-icon input{border:none; height: 46px; padding:0 1px;}
.verification-status{flex: 0 0 auto; padding-top: 0px; display: flex; align-items: center; color: #667085;  font-size: 13px; line-height: 24px; }
.verification-status span svg{margin-left: 10px; color: #E0E0E0; position: relative; top:-1px;}
.text-muted{width: 100%; margin-top: 5px; display: block;}

/* .mobile-verification-status{flex: 0 0 auto; padding-top: 0px; display: flex; align-items: center; color: #667085;  font-size: 13px; line-height: 24px; } */
/* .mobile-verification-status span svg{margin-left: 10px; color: #E0E0E0; position: relative; top:-1px;} */
.validation-text.verified span{align-items: center !important; color: #667085 !important;  font-size: 13px !important; }
.validation-text.verified svg {
    font-size: 13px !important;
}
.edit-popup .notification-gtp {display: flex;width: 100%; 	justify-content: space-between;	gap: 10px;}
.edit-popup .notification-gtp .custom-checkbox {width: 100%;cursor: pointer;	max-width: calc(100% / 2 - 10px);	font-size: 16px;	line-height: 24px;	border: 1px solid #D9D9D9;	padding: 11px 16px;	border-radius: 99px;	display: flex;	justify-content: space-between;	align-items: center; flex-direction: row-reverse;}


.edit-popup .notification-gtp .custom-checkbox input{width: 17px; height: 17px;}
.edit-popup .custom-checkbox label{max-width: inherit;}

.edit-popup .txx-fld select{height: 48px;}
.edit-popup .list-group{width: 100%; gap:0; }
.edit-popup .group-items{width: 100%; display: flex; align-items: center;}
.edit-popup .group-items  button{padding:0; line-height: 16px; padding: 12px 16px;}

.permissions-gtp{flex-wrap: wrap;}
.save-btn{width: 100%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
.save-btn:hover{background:#3D95A8; }
.verified {fill: #3D95A8;}

.edit-popup .select-dropdown__control input{height: auto;}
.edit-popup .select-dropdown__control {height: 48px;}

.tp-align{align-items: flex-start; }
.tp-align .form-label{padding-top: 15px;}
.role-gtp{flex-wrap: wrap;}
.team-items-name svg{
    margin-top: 2px;
}

.input-with-icon em {
    font-style: normal;
    padding: 14px 0px;
}
.input-with-icon {
  justify-content: space-between;
}


@media only screen and (max-width:859px){

    .edit-popup{padding-left: 0px !important;}
    .modal-dialog{max-width: 96%; }
    .modal-header{padding: 20px; padding-bottom: 0;}
    .modal-body{padding:20px;}
}


@media only screen and (max-width:767px){
    .modal-title{font-size: 20px; line-height: 28px;}
    .modal-fld{flex-wrap: wrap;}
    .modal-fld Label{max-width: 100%; font-size:16px; color: #040F0F; line-height:20px;} 
    .txx-fld{max-width: 100%; margin-top: 13px;}
    .modal-fld{margin-bottom: 32px;}
    .role-gtp, .permissions-gtp{margin-top: 13px;   }
    .team-items-name .list-group-item{max-width: 100%;}

    .tp-align .form-label{padding-top: 0;}
}


@media only screen and (max-width:479px){
    .permissions-gtp,  .role-gtp{flex-wrap: wrap; gap:8px;}
    .permissions-gtp  .custom-checkbox ,  .role-gtp .custom-radio{max-width: 100% !important;} 
    
    .permissions-gtp{flex-wrap: wrap; gap:8px;}
    .permissions-gtp .custom-radio{max-width: 100% !important;} 
}

@media only screen and (max-width:575px){
    .input-with-icon em {
        font-style: normal;
        word-wrap: break-word;
        /* white-space: break-spaces; */
        /* display: inline-block; */
        max-width: calc(100% - 150px);
    }
    .verification-status{
        max-width: calc(100% - 180px);
    }
   
    }
