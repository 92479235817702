.phone-input-container {
    display: flex;
    flex-direction: column; /* Align content in a single column */
    position: relative;
  }
  
  .phone-input-container .PhoneInput {
    display: flex;
    align-items: center;
    width: 100%; /* Ensure it spans the container */
  }
  
  .phone-input-container .PhoneInputInput {
    flex: 1; /* Allow the input field to grow */
    padding-left: 10px; /* Adjust padding to align nicely */
    height: 40px; /* Ensure consistent height with the flag */
    border: 1px solid #ccc; /* Optional for better visibility */
    border-radius: 4px; /* Optional for styling */
  }
  
  .phone-input-container .PhoneInputCountry {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; /* Space between the flag and input */
  }
  
  .phone-input-container .validation-text {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .phone-input-container .validation-text span {
    font-size: 13px;
    /* font-weight: bold; */
  }
  
  .phone-input-container .validation-text.verified span {
    color: green;
  }
  
  .phone-input-container .validation-text.pending span {
    color: #667085;
  }
  
  /* Mobile View Adjustments */
@media (max-width: 768px) {
    .phone-input-container {
      flex-direction: column; /* Stack elements vertically on mobile */
      align-items: flex-start; /* Align items to the left */
    }
  
    .phone-input-container .form-control {
      width: 100%; /* Ensure input spans full width */
    }

    .phone-input-container .validation-text.pending {
        width: 35%;
        padding-left: 10px;
      }

    .phone-input-container .validation-text.pending span{
      font-size: 10px;
      text-align: right; /* Align text to the right */
      display: inline-block; /* Ensure the text is aligned within the span */
    }
  }