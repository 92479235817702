.date-picker-custom {
    width: 100%;
}

.form-group-parent{
    display: flex;
    flex-direction: column;
}


/* multiselect */

.custom-form-group {
    width: 100%;
    background-color: white;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .custom-dropdown {
    width: 100%;
  }
  
  .custom-dropdown-toggle {
    width: 100%;
    background-color: white !important;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #333;
    text-align: left;
    padding: 10px;
  }

  .custom-dropdown-toggle:focus,
  .custom-dropdown-toggle:active {
    box-shadow: none;
  }

  /* .custom-dropdown-toggle {
    background-color: white !important;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
   */
  .custom-dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc ;
    box-shadow: none;
    padding: 10px;
  }

  /* .custom-dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
  } */
  
  .custom-checkbox {
    padding-left: 10px;
  }
  
  .required-asterisk {
    color: red;
  }
  
  .modal-dialog{max-width: 860px; }
  .modal-header{border:none; padding:40px; padding-bottom: 0;}
  .modal-body{padding:40px; padding-top: 24px; }
  .modal-title{font-size: 24px; line-height: 30px; font-weight: 600; color: #040F0F;}
  .modal-header .btn-close{background-size: 11px 11px;}
  
  .modal-fld{display: flex;  align-items: center; margin-bottom: 16px; flex-wrap: wrap; }
  .modal-fld Label{max-width: 162px; width: 100%; flex:  0 0 auto;  margin-bottom:0; font-size: 16px; color:#040F0F; font-weight:400;}
  .txx-fld{max-width: calc(100% - 162px); width: 100%;}
  
  .txx-fld input{border: 1px solid #D9D9D9; height: 48px;  background: #fff; font-size: 16px; color: #181818; border-radius: 4px; width: 100%;}
  .input-with-icon{display: flex; border:1px solid #D9D9D9; padding: 0 10px;  width: 100%; border-radius: 4px;}
  .input-with-icon input{border:none; height: 46px; padding:0 1px;}
  .verification-status{flex: 0 0 auto; padding-top: 0px; display: flex; align-items: center; color: #667085;  font-size: 16px; line-height: 24px; }
  .verification-status span svg{margin-left: 10px; color: #E0E0E0; position: relative; top:-1px;}
  .text-muted{width: 100%; margin-top: 5px; display: block;}
    
  
  .confirm-popup .notification-gtp {display: flex;width: 100%; 	justify-content: space-between;	gap: 10px;}
  .confirm-popup .notification-gtp .custom-checkbox {width: 100%;cursor: pointer;	max-width: calc(100% / 2 - 10px);	font-size: 16px;	line-height: 24px;	border: 1px solid #D9D9D9;	padding: 11px 16px;	border-radius: 99px;	display: flex;	justify-content: space-between;	align-items: center; flex-direction: row-reverse;}
  
  
  .confirm-popup .notification-gtp .custom-checkbox input{width: 17px; height: 17px;}
  .confirm-popup .custom-checkbox label{max-width: inherit;}
  
  .confirm-popup .txx-fld select{height: 48px;}
  .confirm-popup .list-group{width: 100%; gap:0; }
  .confirm-popup .group-items{width: 100%; display: flex; align-items: center;}
  .confirm-popup .group-items  button{padding:0; line-height: 16px; padding: 12px 16px;}
  
  .permissions-gtp{flex-wrap: wrap;}
  .save-btn{width: 100%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
  .save-btn:hover{background:#3D95A8; }
  
  
  .confirm-popup .select-dropdown__control input{height: auto;}
  .confirm-popup .select-dropdown__control {height: 48px;} 
  
  
  .tp-align{align-items: flex-start; }
  .tp-align .form-label{padding-top: 15px;}
  
  .another-org {border:1px solid #D9D9D9; padding:16px; position:relative; max-width: calc(100% - 162px); margin-left: auto; margin-bottom: 16px;}
  .another-org .cross-icon {	position: absolute;	right: -8px; top: -9px;	width: 24px;	height: 24px;	border-radius: 50%;background: #2E3394;	color: #fff;	display: flex;	align-items: center;	justify-content: center; cursor: pointer;}
  .org-teams .txx-fld{border:1px solid #D9D9D9; padding:16px;}
  .role-gtp{flex-wrap: wrap;}
  
  .another-org .modal-fld label{display: none;}
  .another-org  .txx-fld{max-width: 100%;}
  

  .as-select-input select{height: 48px;}
  .master-chioce-box .form-check input{width: 20px; height: 20px;}
  .master-chioce-box label{max-width: initial !important;}

  .master-chioce-box .form-check input:checked{background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");}
  .master-chioce-box button{height: 48px;}
  .master-chioce-box select {height: 48px;}

  @media only screen and (max-width:859px){
  
      .confirm-popup{padding-left: 0px !important;}
      .modal-dialog{max-width: 96%; }
      .modal-header{padding: 20px; padding-bottom: 0;}
      .modal-body{padding:20px;}
  }
  
  
  @media only screen and (max-width:767px){
      .modal-title{font-size: 20px; line-height: 28px;}
      .modal-fld{flex-wrap: wrap;}
      .modal-fld Label{max-width: 100%; font-size:16px; color: #040F0F; line-height:20px;} 
      .txx-fld{max-width: 100%; margin-top: 13px;}
      .modal-fld{margin-bottom: 32px;}
      .role-gtp, .permissions-gtp{margin-top: 13px;}
      .tp-align .form-label{padding-top: 0;}
      .another-org{max-width: 100%;}
      .another-org .modal-fld{margin-bottom: 0;}
      .another-org{padding-top: 0;}
      .modal {padding-left: 0px !important;}
  }
  
  
  @media only screen and (max-width:479px){
      .permissions-gtp,  .role-gtp{flex-wrap: wrap; gap:8px;}
      .permissions-gtp  .custom-checkbox ,  .role-gtp .custom-radio{max-width: 100% !important;} 
      
      .permissions-gtp{flex-wrap: wrap; gap:8px;}
      .permissions-gtp .custom-radio{max-width: 100% !important;} 

    
  }