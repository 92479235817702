.main-container {
  font-family: 'Poppins', sans-serif;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  position: absolute;
  overflow: auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.otp-card {
  background: #ffffff; 
  border-radius: 15px;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  text-align: center;
}

.otp-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  margin-top: 20px;
  line-height: normal;
}

.otp-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.otp-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.otp-inputs .otp-box {
  width: 50px;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 8px;
  background: #f7f7f7; 
  transition: border-color 0.3s, box-shadow 0.3s;
}

.otp-box:focus {
  outline: none;
  border-color: #4a90e2; 
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.3); 
}

.otp-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.otp-button {
  flex: 1;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.otp-submit-button {
  background: linear-gradient(135deg, #4a90e2, #007bff);
  color: white;
}

.otp-submit-button:disabled {
  background: #ddd;
  color: #999;
  cursor: not-allowed;
}

.otp-clear-button {
  background: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
}

.otp-clear-button:disabled {
  background: #ddd;
  color: #999;
  cursor: not-allowed;
}

.otp-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.otp-button:active {
  transform: translateY(0);
}

.otp-footer-text {
  font-size: 16px;
  color: #555;
  margin-top: 20px;
}

.custom-alert {
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

/* Styles for mobile view */
@media (max-width: 768px) {
  .otp-inputs .otp-box {
    width: 40px; 
    height: 40px; 
    font-size: 14px; 
    margin: 2px; 
  }

}
@media  (max-width: 922px) and (orientation: landscape) {
  .otp-card {
    padding: 15px;
  }
}
