.close-btn{
    text-decoration: none;
    font-size: 10px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 1px;
}

.add-btn{
    margin-top: 5px;
}

.badge-container-box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-btn{
    display: flex;
    justify-items: space-between;
    align-items: center;
    gap: 5px;
}

.email-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.removable-badge {
    display: inline-flex;
    align-items: center;
    background-color: #f2f2f2;
  }
  
  .badge-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #b90707 !important; */
    margin-left: 0.5rem;
    padding: 2px 5px !important;
    border-radius: 50%;
    color: #ee5959 !important;
    text-decoration: none;
  }

    
  .custom-alert {
    margin-block: 4px !important;
    font-size: 14px;
    text-align: center;
  }


.add-email-btn{width: 100%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394; }
.add-email-btn:hover{background:#3D95A8; border: #fff;}

.resend-email-btn{width: 30%; padding: 14px 15px; font-size: 16px; margin-top: 10px;  border-radius: 8px; line-height: 26px; color: #fff; background:#2E3394 !important; }
.resend-email-btn:hover{background:#3D95A8 !important; border: #fff;}


@media (max-width: 1024px) {
    .resend-email-btn {
        width: auto
    }
  }